import React, { useCallback, useState } from "react";
import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useSelector } from "react-redux";

import usePageTitle from "../../hooks/usePageTitle";
import BranchChannelFilters from "./BranchChannelFilters";
import SplitBrokersTable from "./SplitBrokersTable";

export default function SplitBrokers() {
  usePageTitle("Split Brokers");
  const [success, setSuccess] = useState(null);
  const [response, setResponse] = useState("");
  const uniqueChannels = useSelector(
    (state) => state.formOptions.splitBrokerChannels,
  );
  const apiCallback = useCallback((apiSuccess, result) => {
    setSuccess(apiSuccess);
    if (apiSuccess) {
      if (result?.response?.data) {
        setResponse(JSON.stringify(result.response.data));
      } else {
        setResponse("Saved Successfully");
      }
    } else {
      setResponse(JSON.stringify(result.response.data));
    }
  }, []);

  const commonColumns = () => [
    {
      header: "DD BRANCH ID",
      accessorKey: "dd_physical_branch_id",
    },
    {
      header: "Channel",
      accessorKey: "channel",
      editVariant: "select",
      editSelectOptions: uniqueChannels,
    },
  ];

  return (
    <div>
      <div style={{ padding: 20 }}>
        <SplitBrokersTable
          study={null}
          direction={null}
          title="Split Brokers"
          url="branch/channel"
          editUrl="branch/{ddPhysicalBranchId}/channel"
          columns={[
            ...commonColumns(),
            {
              header: "Firm CRD",
              accessorKey: "firm_crd",
            },
            {
              header: "Notes",
              accessorKey: "notes",
            },
            {
              header: "Timestamp",
              accessorKey: "timestamp",
              enableEditing: false,
            },
          ]}
          apiCallback={apiCallback}
          filterPanel={BranchChannelFilters}
          genericEditActions
        />
      </div>
      <Snackbar
        open={success !== null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <div>
          <Alert
            onClose={() => setSuccess(null)}
            severity={success ? "success" : "error"}
          >
            {response}
          </Alert>
        </div>
      </Snackbar>
    </div>
  );
}
