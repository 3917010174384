import React from "react";
import * as Yup from "yup";

import Lock from "../../../components/input/Formik/Lock";
import LockToCompare from "../../../components/input/Formik/LockToCompare";
import TimePeriod from "../../../components/input/Formik/TimePeriod";
import LMSCheckbox from "../../../components/input/Formik/base/LMSCheckBox";
import LMSSelect from "../../../components/input/Formik/base/LMSSelect";
import LMSTextField from "../../../components/input/Formik/base/LMSTextField";
import { LMSForm } from "../common/LMSForm";

export const AnnStandardReportSchema = Yup.object().shape({
  time_period: Yup.string().required("Required"),
  start_quarter: Yup.string().required("Required"),
  end_quarter: Yup.string().required("Required"),
  lock_to_compare: Yup.string()
    .when("is_qa_report", {
      is: true,
      then: () => Yup.string().required("Field is required"),
    }),
});

export default function AnnStandardReportForm({
  title,
  client,
  studyType,
  step,
  setStep,
  submitHandler,
  taskConfig,
  setIsRequired,
}) {
  return (
    <LMSForm
      client={client}
      studyType={studyType}
      step={step}
      setStep={setStep}
      submitHandler={submitHandler}
      taskConfig={taskConfig}
      validationSchema={AnnStandardReportSchema}
      title={title}
      requiredInputAdded
      setIsRequired={setIsRequired}
    >
      <TimePeriod required />
      <Lock studyType="ann" />
      <LMSCheckbox label="Generate QA Report" name="is_qa_report" />
      <LockToCompare
        studyType="ann"
        helperText="For QA report: Lock dataset to query from"
      />
      <LMSTextField
        label="Dollar Threshold"
        name="dollar_threshold"
        helperText="Minimun dollar difference to show on the report"
      />
      <LMSTextField
        label="Percent Threshold"
        name="percent_threshold"
        type="number"
        helperText="Minimun percent difference to show on the report"
      />
      <LMSSelect
        label="Percent Threshold Apply Condition"
        name="apply_condition"
        helperText="To flag a row when dollar threshold and/or percent threshold are met"
        options={[
          {
            id: "and",
            label: "AND",
          },
          {
            id: "or",
            label: "OR",
          },
        ]}
      />
      <LMSCheckbox label="Disable Phantom Exclusions" name="disable_phantom_exclusions" />
    </LMSForm>
  );
}
