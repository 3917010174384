import React, { useCallback, useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import UploadIcon from "@mui/icons-material/Upload";
import { ButtonGroup, Button } from "@mui/material";
import _ from "lodash";
import { useSelector } from "react-redux";
import MaterialTable from "../../../components/Table";

import { API, generateTableCallbacks } from "../../../utils/api";
import { combineArrays } from "../../../utils/dataTransformations";
import ConsortiumBulkUploadDialog from "./ConsortiumBulkUploadDialog";

export default function ExclusionsTable({
  includeFirmNamesData = false,
  study,
  direction,
  level,
  url,
  editUrl,
  columns,
  title,
  apiCallback,
  genericEditActions = false,
  setSubmitMessage,
}) {
  const [loading, setLoading] = useState(true);
  const fetchingFirmNames = useSelector(
    (state) => state.application.fetchingBackground.firmNamesDD,
  );
  const firmNames = useSelector((state) => state.formOptions.firmNamesDD);
  const [dataWithFirmNames, setDataWithFirmNames] = useState();
  const [bulkUploadDialogOpen, setBulkUploadDialogOpen] = useState(false);
  const [data, setData] = useState({ rows: [], total: 0 });

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await API.request({
        url,
        method: genericEditActions ? "GET" : "POST",
      });
      setData(response.data);

      if (includeFirmNamesData && !_.isEqual(firmNames, ["Loading..."])) {
        setDataWithFirmNames(
          combineArrays(response.data.rows, firmNames, "firm_crd"),
        );
      }
    } catch {
      setData({ rows: [], total: 50 });
    } finally {
      setLoading(false);
    }
  }, [genericEditActions, url, firmNames, includeFirmNamesData]);

  useEffect(() => {
    getData();
  }, [getData]);

  const stableColumns = columns.map((column) => ({ ...column }));

  return (
    <div style={{ width: "100%" }}>
      <MaterialTable
        columns={stableColumns}
        enableColumnFilterModes
        data={(fetchingFirmNames || !includeFirmNamesData) ? data.rows ?? [] : dataWithFirmNames ?? []}
        title={title}
        isLoading={loading || (includeFirmNamesData && !fetchingFirmNames && !dataWithFirmNames)}
        initialState={{ pagination: { pageSize: 5, pageIndex: 0 } }}
        renderTopToolbarCustomActions={({ table }) => (
          <ButtonGroup>
            <Button
              variant="contained"
              onClick={() => table.setCreatingRow(true)}
              style={{ marginRight: 10 }}
              endIcon={<AddIcon />}
            >
              Add New
            </Button>
            <Button
              variant="contained"
              onClick={() => setBulkUploadDialogOpen(true)}
              endIcon={<UploadIcon />}
            >
              Bulk Upload
            </Button>
          </ButtonGroup>
        )}
        filterFns={{
          startsWith: (row, id, filterValue) => String(row.getValue(id) ?? null)
            .toLowerCase()
            .startsWith(filterValue.toLowerCase()),
          endsWith: (row, id, filterValue) => String(row.getValue(id) ?? null)
            .toLowerCase()
            .endsWith(filterValue.toLowerCase()),
          contains: (row, id, filterValue) => String(row.getValue(id) ?? null)
            .toLowerCase()
            .includes(filterValue.toLowerCase()),
          equals: (row, id, filterValue) => String(row.getValue(id) ?? null).toLowerCase()
            === filterValue.toLowerCase(),
        }}
        localization={{
          filterStartsWith: "Starts With",
          filterEndsWith: "Ends With",
          filterContains: "Contains",
          filterEquals: "Equals(Ignore Case)",
        }}
        editable={generateTableCallbacks(
          editUrl || url,
          null,
          null,
          data?.rows || [],
          (success, response) => {
            apiCallback(success, response);
            getData();
          },
          setLoading,
          level,
        )}
      />

      <ConsortiumBulkUploadDialog
        open={bulkUploadDialogOpen}
        setOpen={setBulkUploadDialogOpen}
        setSubmitMessage={setSubmitMessage}
        level={level}
        study={study}
        direction={direction}
      />
    </div>
  );
}
