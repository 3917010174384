import * as React from "react";
import { useState } from "react";
import { Button, Tabs, Tab, Typography, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import usePageTitle from "../../hooks/usePageTitle";
import ConsortiumExportDialog from "./exclusions/ConsortiumExportDialog";
import Exclusions from "./exclusions/Exclusions";
import SyncDialog from "./exclusions/SyncDialog";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

export default function ConsortiumTabs({ setSubmitMessage }) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [syncDialogopen, setSyncDialogOpen] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  usePageTitle("Consortium Exclusions");

  const valueToStudy = {
    0: "ann",
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{ borderBottom: 1, borderColor: "divider" }}
        style={{ marginBottom: 30 }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="ANN" {...a11yProps(0)} />
        </Tabs>
      </Box>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={() => setExportDialogOpen(true)}
      >
        Export
      </Button>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={() => setSyncDialogOpen(true)}
      >
        Sync Data
      </Button>

      <ConsortiumExportDialog
        open={exportDialogOpen}
        setOpen={setExportDialogOpen}
        study={valueToStudy[value]}
        setSubmitMessage={setSubmitMessage}
      />

      <SyncDialog
        open={syncDialogopen}
        setOpen={setSyncDialogOpen}
        study={valueToStudy[value]}
        setSubmitMessage={setSubmitMessage}
      />

      <TabPanel value={value} index={0}>
        <Exclusions study="ann" setSubmitMessage={setSubmitMessage} />
      </TabPanel>
    </Box>
  );
}
